import React from "react";
import './Background.css'

const Background = props => (

    <div className="Gridgroup">
      <div className="Col"></div>
      <div className="Col"></div>
      <div className="Col"></div>
      <div className="Col"></div>
      <div className="Col"></div>
      <div className="Col"></div>
      <div className="Col"></div>
      <div className="Col"></div>
      <div className="Col"></div>
      <div className="Col"></div>
      <div className="Col"></div>
      <div className="Col"></div>
    </div>
)

export default Background