import React, {useState} from "react";
import { Link } from "gatsby"
import './Menu.css'


function Menu(props) {


const [nav, showNav] = useState(false)



return(
<div className="Menu">
    <button onClick={()=> showNav (!nav)} className={nav ? 'MenuIcon_close': 'MenuIcon'} > 
        <div className="bars1"/>
        <div className="bars2"/>
        <div className="bars3"/>
    </button>

    <div className={nav ? 'MenuDrawer' : 'MenuDrawer_active' }>
        <ul>
        <Link to="/">Design</Link>
        <Link to="/Life">Life</Link>
           
            <Link className="listTitle" to="/#work">Work</Link>
            <Link to="/Perf">Perfeqta.io</Link>
            <Link to="/Edgelink">EdgeLink</Link>
            <Link to="/Maintain">Maintain</Link>
        </ul>
    </div>
</div>
    );

}
export default Menu

