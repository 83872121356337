import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import './header.css'
import Menu from "../components/Menu"

class Header extends React.Component { 

  constructor (props) {
    super (props)

    this.state = {
      hasScrolled: false
    }
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handelScroll)
  }

  handelScroll = (event) => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 64 ) {
      this.setState({hasScrolled: true})
    }

    else {
      this.setState({hasScrolled: false})
    }
  }


  render() {

    return(
      <header className= {this.state.hasScrolled ? 'Header HeaderScrolled' : 'Header'}>
        <div className="LinkWrapper"> 
          <div className="spacer"/>
          <img className="logo" src={ require('../images/logo.svg').default} alt="Braden Crumly Design" />
          <div className="spacer"/>
          <Link to="/">Design</Link>
          <div className="spacer"/>
          <Link to="/Life">Life</Link>
          <div className="spacer"/>  
          <Link to="/#work">Work</Link>
          <div className="spacer"/>  
          <Menu className="Menu"/>
        </div> 
    </header>)
  }
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Title`,
}


 
export default Header

